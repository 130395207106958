<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technicians/Schedule</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <section class="users-list-wrapper">
        <div class="row pb-2">
          <div class="col-lg-4 col-xl-2">
            <ul class="px-0 bg-white">
              <li v-for="(singleState, stateIndex) in state" :key="stateIndex" class="d-block py-1 pl-1 state-side-bar-nav-item" :class="[selectedState.value === singleState.value ? 'div-exact-active' : '']">
                <div @click="selectedState = singleState" class="d-flex align-items-center">
                  <span> {{ singleState.name }} </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="card col-lg-8 col-xl-10 pb-2">
            <div class="d-flex justify-content-center align-items-center mt-1">
              <img :src="singleTechnician.user.avatar" :alt="`${technicianFullName}-avatar`" style="height: 2rem; width: 2rem" class="img-thumbnail">
              <h5 class="text-uppercase mx-1">{{ technicianFullName }}</h5>
            </div>
            <hr class="py-0 mt-1 mb-0">
            <div v-for="(stateSchedule,stateIndex) in technicianSchedules" :key="stateIndex">
              <div v-if="selectedState.value === stateSchedule.stateValue" class="card-body col-12">
                <div class="d-flex flex-column">
                  <h3 class="text-center" style="font-size: 20px;"> {{ stateSchedule.stateName }}</h3>
                  <div class="custom-control custom-switch custom-control-inline align-items-center justify-content-center">
                    <div>
                      <input v-model="stateSchedule.stateWorkingStatus" type="checkbox" class="custom-control-input" checked :id="`stateWorkingStatus-${stateIndex}`">
                      <label class="custom-control-label mr-1" :for="`stateWorkingStatus-${stateIndex}`"></label>
                    </div>
                    <span class="font-medium-1">{{stateSchedule.stateWorkingStatus ? "Active" : "Inactive"}}</span>
                  </div>
                </div>
                <div v-if="stateSchedule.stateWorkingStatus" class="mt-1">
                  <div class="row d-flex justify-content-between rounded py-1 my-1" :style="statePreferenceRowStyle">
                    <h6 class="ml-1">State Service Preference</h6>
                    <ul class="list-unstyled mb-0">
                      <li class="d-inline-block mr-2 mb-0">
                        <fieldset>
                          <div class="radio">
                            <input v-model="stateSchedule.statePreference" type="radio"  name="statePreference" value="onsite" id="onsitePreference">
                            <label for="onsitePreference">Onsite</label>
                          </div>
                        </fieldset>
                      </li>
                      <li class="d-inline-block mr-2 mb-0">
                        <fieldset>
                          <div class="radio">
                            <input v-model="stateSchedule.statePreference" type="radio" name="statePreference" value="remote" id="remotePreference">
                            <label for="remotePreference">Remote</label>
                          </div>
                        </fieldset>
                      </li>
                      <li class="d-inline-block mr-2 mb-0">
                        <fieldset>
                          <div class="radio">
                            <input v-model="stateSchedule.statePreference" type="radio" name="statePreference" value="both" id="bothPreference">
                            <label for="bothPreference">Both</label>
                          </div>
                        </fieldset>
                      </li>
                    </ul>
                  </div>
                  <div v-for="(daySchedule, dayScheduleIndex) in stateSchedule.stateDayWiseSchedules" :key="dayScheduleIndex">
                    <div class="row d-flex justify-content-between rounded py-1 my-1"  :style="stateDaysRowStyle[dayScheduleIndex]">
                      <h6 class="ml-1">{{ daySchedule.day }}</h6>
                      <div class="custom-control custom-switch custom-control-inline  d-flex align-items-center">
                        <div>
                          <input v-model="daySchedule.dayWorkingStatus" type="checkbox" class="custom-control-input" checked :id="`dayWorkingStatusCheckbox-${stateIndex}-${dayScheduleIndex}`">
                          <label class="custom-control-label mr-1" :for="`dayWorkingStatusCheckbox-${stateIndex}-${dayScheduleIndex}`">
                          </label>
                        </div>
                        <span class="font-medium-1">{{ daySchedule.dayWorkingStatus ? 'Active' : 'Inactive'}}</span>
                      </div>
                    </div>
                    <div v-if="daySchedule.dayWorkingStatus" class="row col-12">
                      <div class="form-group col-sm-12 col-md-4">
                        <label :for="`dayStartTime-${stateIndex}-${dayScheduleIndex}`">Day Start Time</label>
                        <input v-model="daySchedule.dayStartTime" type="time" :min="workingSchedule.start" :max="workingSchedule.end" class="form-control" :id="`dayStartTime-${stateIndex}-${dayScheduleIndex}`" :aria-describedby="`dayStartTimeHelp-${stateIndex}-${dayScheduleIndex}`">
                        <small :id="`dayStartTimeHelp-${stateIndex}-${dayScheduleIndex}`" class="form-text text-muted">The system will not evaluate any time before {{ workingSchedule.start }}</small>
                      </div>
                      <div class="form-group col-sm-12 col-md-4">
                        <label :for="`dayEndTime-${stateIndex}-${dayScheduleIndex}`">Day End Time</label>
                        <input v-model="daySchedule.dayEndTime" type="time" :min="workingSchedule.start" :max="workingSchedule.end" class="form-control" :id="`dayEndTime-${stateIndex}-${dayScheduleIndex}`" :aria-describedby="`dayEndTimeHelp-${stateIndex}-${dayScheduleIndex}`">
                        <small :id="`dayEndTimeHelp-${stateIndex}-${dayScheduleIndex}`" class="form-text text-muted">The system will not evaluate any time after {{ workingSchedule.start }}</small>
                      </div>
                      <div class="form-group col-sm-12 col-md-4">
                        <div class="custom-control custom-switch custom-control-inline align-items-center" style="margin-top: 2rem">
                          <div>
                            <input v-model="daySchedule.dayBreakStatus" type="checkbox" class="custom-control-input" checked :id="`dayBreakStatus-${stateIndex}-${dayScheduleIndex}`">
                            <label class="custom-control-label mr-1" :for="`dayBreakStatus-${stateIndex}-${dayScheduleIndex}`"></label>
                          </div>
                          <span class="font-medium-1">{{ daySchedule.dayBreakStatus ? "Has Break" : "No Break"}}</span>
                        </div>
                      </div>
                    </div>
                    <hr class="py-0 my-0" v-if="daySchedule.dayBreakStatus">
                    <div v-if="daySchedule.dayBreakStatus">
                      <div class="d-flex justify-content-between mt-1">
                        <span>Break Details</span>
                        <button @click="addTechnicianBreak(stateIndex, dayScheduleIndex)" type="button" class="btn btn-sm btn-primary btn-link">Add Break</button>
                      </div>
                      <div v-for="(breakObj, dayBreakIndex) in daySchedule.dayBreakDetails" :key="dayBreakIndex" class="row col-12" >
                        <div class="form-group col-sm-12 col-md-4">
                          <label :for="`breakStartTime-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`">Break Start Time</label>
                          <input v-model="breakObj.breakStartTime" type="time" :min="workingSchedule.start" :max="workingSchedule.end" class="form-control" :id="`breakStartTime-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`" :aria-describedby="`breakStartTimeHelp-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`">
                          <small :id="`breakStartTimeHelp-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`" class="form-text text-muted">The system will not evaluate any time before {{ workingSchedule.start }}</small>
                        </div>
                        <div class="form-group col-sm-12 col-md-4">
                          <label :for="`breakEndTime-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`">Break End Time</label>
                          <input v-model="breakObj.breakEndTime" type="time" :min="workingSchedule.start" :max="workingSchedule.end" class="form-control" :id="`breakEndTime-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`" :aria-describedby="`breakEndTimeHelp-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`">
                          <small :id="`breakEndTimeHelp-${stateIndex}-${dayScheduleIndex}-${dayBreakIndex}`" class="form-text text-muted">The system will not evaluate any time after {{ workingSchedule.end }}</small>
                        </div>
                        <div class="form-group">
                          <div class="mt-2">
                            <button @click="removeTechnicianBreak(stateIndex, dayScheduleIndex, dayBreakIndex)" class="btn btn-warning delete-time-btn" :disabled="daySchedule.dayBreakStatus && daySchedule.dayBreakDetails.length <=1">Remove</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="d-flex justify-content-end">
              <button @click="updateTechnicianSchedule()" type="button" class="btn btn-primary glow mb-0">
                <span class="align-middle ml-25">Save Changes</span>
              </button>
            </div>
          </div>
        </div>

      </section>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

// core package
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "TechnicianScheduleEdit",

  mixins: [ShowToastMessage],

  components: {
    AppLayout,
  },
  data() {
    return {
      singleTechnician: {
        id: this.$route.params.id,
        schedule: [],
        user: {
          id: '',
          firstName: '',
          lastName: '',
          avatar: '/app-assets/defaults/images/users/avatar.svg',
        },
      },

      technicianSchedules: [],

      statePreferenceRowStyle: {
        backgroundColor: '#e5e7ec',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
      },

      stateDaysRowStyle: [
        {
          backgroundColor: '#ecf4ff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fdf4e9',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#f1fff1',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fff7f7',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#effbff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fbf7ff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fbffed',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },


      ],

      selectedState: {
        name: '',
        value: '',
      },

      getTechnicianParams: {
        with_relation: ['user', 'user.media', 'remoteTechnician', 'onSiteTechnician'],
      },

      getSettingsParams: {
        type: ['default', 'technician'],
        key: ['default_state', 'technician_working_schedule'],
      }
    }

  },
  computed: {

    ...mapGetters({
      previousRoute: 'previousRoute',
      technician: 'appTechnicians/technician',
    }),

    state() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    workingSchedule() {
      return this.$store.getters['appSettings/settingTechnicianWorkingSchedule'].value
    },

    technicianFullName() {
      return `${this.singleTechnician.user.firstName} ${this.singleTechnician.user.lastName}`;
    },

    putTechnicianData() {
      return {
        schedule: JSON.stringify(this.technicianSchedules),
      }
    },

    technicianDefaultStatePreference() {
      if ((this.technician && this.technician.onSiteTechnician && this.technician.onSiteTechnician.status === 'Active')
          && (this.technician && this.technician.remoteTechnician && this.technician.remoteTechnician.status === 'Active'))
      {
        return 'both';
      }

      if ((this.technician && this.technician.onSiteTechnician && this.technician.onSiteTechnician.status === 'Active'))
      {
        return 'onsite';
      }

      if ((this.technician && this.technician.remoteTechnician && this.technician.remoteTechnician.status === 'Active'))
      {
        return 'remote';
      }
      return '';
    }
  },
  methods: {
    ... mapActions({
      getSettings: 'appSettings/getSettings',
      getTechnician: 'appTechnicians/getTechnician',
      putTechnician: 'appTechnicians/putTechnician',
    }),

    async getSingleTechnician() {
      let ParamObj = {
        id: this.$route.params.id,
        params: this.getTechnicianParams,
      };

      await this.getTechnician(ParamObj).then(async response => {

        if (response && response.status && response.status === 200) {
          this.singleTechnician.user.id         = this.technician && this.technician.user     && this.technician.user.id              ? this.technician.user.id               : '';
          this.singleTechnician.user.firstName  = this.technician && this.technician.user     && this.technician.user.first_name      ? this.technician.user.first_name       : '';
          this.singleTechnician.user.lastName   = this.technician && this.technician.user     && this.technician.user.last_name       ? this.technician.user.last_name        : '';
          this.singleTechnician.user.avatar     = this.technician && this.technician.user     && this.technician.user.avatar          ? this.technician.user.avatar           : '';
          this.singleTechnician.schedule        = this.technician && this.technician.schedule ? JSON.parse(this.technician.schedule)  : [];

        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async buildTechnicianSchedules() {
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      let stateSchedules = [];
      await this.state.forEach( singleState => {
        let dayWiseSchedules = [];

        days.forEach(day => {
          let daySchedule = {
            day: day,
            dayWorkingStatus: false,
            dayStartTime: this.workingSchedule.start,
            dayEndTime: this.workingSchedule.end,
            dayBreakStatus: false,
            dayBreakDetails: [
              {
                breakStartTime: this.workingSchedule.start,
                breakEndTime: this.workingSchedule.end,
              },
            ],

          };

          dayWiseSchedules.push(daySchedule);
        });

        let stateSchedule = {
          stateName: singleState.name,
          stateValue: singleState.value,
          stateWorkingStatus: false,
          statePreference: this.technicianDefaultStatePreference, // onsite, remote, both
          stateDayWiseSchedules: dayWiseSchedules,
        };

        stateSchedules.push(stateSchedule);
      });

      return stateSchedules;
    },

    async addTechnicianBreak(stateIndex, dayScheduleIndex) {
      let breakDetails = {
        breakStartTime: this.workingSchedule.start,
        breakEndTime: this.workingSchedule.end,
      };

      this.technicianSchedules[stateIndex].stateDayWiseSchedules[dayScheduleIndex].dayBreakDetails.push(breakDetails);
    },

    async removeTechnicianBreak(stateIndex, dayScheduleIndex, dayBreakIndex) {
      this.technicianSchedules[stateIndex].stateDayWiseSchedules[dayScheduleIndex].dayBreakDetails.splice(dayBreakIndex, 1);
    },

    async updateTechnicianSchedule() {
      let DataObj = {
        id: this.$route.params.id,
        data: this.putTechnicianData,
      };

      await this.putTechnician(DataObj).then(async response => {
        if (response && response.status && response.status === 200) {
          const toastObj = {message: `Technician's schedule updated successful.`, type: 'success'};
          this.showToastMessage(toastObj);
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

  },


  async mounted() {

    await this.getSettings(this.getSettingsParams);
    await this.getSingleTechnician();
    this.technicianSchedules = await (this.singleTechnician.schedule && this.singleTechnician.schedule instanceof Array && this.singleTechnician.schedule.length > 0) ? this.singleTechnician.schedule : await this.buildTechnicianSchedules();
    this.selectedState = await this.state[0];

  },

}
</script>

<style scoped>
h6 {
  color: #475F7B !important;
}

.icon-custom-font-size {
  font-size: 20px;
}

ul {
  text-decoration: none;
  list-style: none;
}

.div-exact-active {
  background-color: var(--primary-color);
  color: white !important;

}

.div-exact-active:hover {
  color: white !important;

}

.state-side-bar-nav-item:hover {
  color: var(--primary-color);
}

.state-side-bar-nav-item {
  cursor: pointer;
  color: #475f7b;
}
</style>
